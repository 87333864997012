









































// @vuese
// @group Views
// Login

import {ForgotPassword as ForgotPasswordRoute} from '../router';
import {validateEmailAddress} from '../components/utils/EmailParser'
import Component from 'vue-class-component'
import { Store } from '@/store/store'

@Component({
  metaInfo: {
    title: 'Login',
  },
})
export default class Login extends Store {
  email = ''
  password = ''
  ForgotPasswordRoute = ForgotPasswordRoute

  get isValid(){
    return validateEmailAddress(this.email).length && this.password;
  }
  /**
   * Login into the platform
   * @arg {string} email
   * @arg {string} password
   * @returns {void}
   * @vuese
   */
  async login(email: string, password: string) {
    this.setSending(true)
    this.portalLogin({email, password})
        .then(async () => {
          this.setSending(false)
          this.$router.push({name: 'Home'});
        })
        .catch((err) => {
          this.setSending(false)
          this.$toast.error('We Failed To Log You In');
        });
  }
};
